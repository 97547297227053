// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Rent
export const getSuppliersList = createAsyncThunk('appSuppliers/getSuppliersList', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1
    
    const response = await axios.get(apiLink('getSuppliers', params))    

    // ** eof issue
    return {
        params,
        data: response.data.suppliers,
        totalPages: response.params
    }
})
export const addSupplier = createAsyncThunk('appSuppliers/addSupplier', async (data) => {
    const response = await axios.post(apiLink('addSupplier'), data)
    return response.data
})
export const updateSupplier = createAsyncThunk('appSuppliers/updateSupplier', async (data) => {
    const response = await axios.put(apiLink({name: 'updateSupplier', value: data.id}), data)
    return response.data
})
export const getSupplier = createAsyncThunk('appSuppliers/getSupplier', async (id) => {
    const response = await axios.get(apiLink({name: 'getSupplier', value: id}))
    return response.data
})
export const removeSupplier = createAsyncThunk('appSuppliers/removeSupplier', async (id) => {
    const response = await axios.delete(apiLink({name: 'removeSupplier', value: id}))
    return response.data
})

// ** Supplies
export const getSuppliesList = createAsyncThunk('appSuppliers/getSuppliesList', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1
    
    const response = await axios.get(apiLink('getSuppliesList', params))    

    // ** eof issue
    return {
        params,
        data: response.data.supplies,
        totalPages: response.params
    }
})
export const addSupply = createAsyncThunk('appSuppliers/addSupply', async (data) => {
    const response = await axios.post(apiLink('addSupply'), data)
    return response.data
})
export const getSupply = createAsyncThunk('appSuppliers/getSupply', async (id) => {
    const response = await axios.get(apiLink({name: 'getSupply', value: id}))
    return response.data
})

export const appSuppliersSlice = createSlice({
    name: 'appSuppliers',
    initialState: {
        suppliers: [],
        supplies: [],
        total: 1,
        params: {},
        settings: [],
        selectedSupplier: null,
        selectedSupply: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(getSuppliersList.fulfilled, (state, action) => {
            state.suppliers = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })
        .addCase(getSuppliesList.fulfilled, (state, action) => {
            state.supplies = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })   
        .addCase(getSupply.fulfilled, (state, action) => {
            state.selectedSupply = action.payload
        })    
    }
})

export default appSuppliersSlice.reducer
