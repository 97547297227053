// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getAccounts = createAsyncThunk('appAccounts/getAccounts', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1
    const response = await axios.get(apiLink('getAccounts', params))

    return {
        params,
        accounts: response.data.paymentAccounts,
        totalPages: response.data.total_elements
    }
})
export const addAccount = createAsyncThunk('appAccounts/addAccount', async (data) => {
    return await axios.post(apiLink('addAccount'), data)
})
export const updateAccount = createAsyncThunk('appAccounts/updateAccount', async (data) => {
    const id = data.id
    delete data.id
    const response = await axios.put(apiLink({name: 'updateAccount', value: id}), data)
    return response.data
})
export const removeAccount = createAsyncThunk('appAccounts/removeAccount', async (id) => {
    const response = await axios.delete(apiLink({name: 'removeAccount', value: id}))
    return {...response.data, id}
})

export const getAccessRoles = createAsyncThunk('appAccounts/getAccessRoles', async (id) => {
    const response = await axios.get(apiLink({name: 'getAccessRoles', value: id}))
    return response.data
})

export const updateAccountAccess = createAsyncThunk('appAccounts/updateAccountAccess', async (data) => {
    const id = data.id
    delete data.id
    const response = await axios.put(apiLink({name: 'updateAccountAccess', value: id}), data)
    return response.data
})

export const getAccountBalance = createAsyncThunk('appAccounts/getAccountBalance', async (data) => {
    const id = data.id
    delete data.id
    const response = await axios.get(apiLink({name: 'getAccountBalance', value: id}, data))
    return {...response.data, id}
})

// ** Payments
export const addPayment = createAsyncThunk('appAccounts/addPayment', async (data) => {
    return await axios.post(apiLink('addPayment'), data)
})
export const removePayment = createAsyncThunk('appAccounts/removePayment', async (id) => {
    const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'PAYMENT_ACCOUNT'}))
    return response.data
})

export const approvePayment = createAsyncThunk('appAccounts/approvePayment', async (data) => {    
    const  response = await axios.post(apiLink({name: 'approvePayment', value: data.payment_id}), data)
    return {response, data}
})

// ** get account payments
export const getSystemPayments = createAsyncThunk('appAccounts/getSystemPayments', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1

    const response = await axios.get(apiLink('getPaymentsList', params))
    return {
        params,
        payments: response.data.response,
        totalPages: response.data.total_elements
    }
})
// ** get subtypes
export const getPaymentSubTypes = createAsyncThunk('appAccounts/getPaymentSubTypes', async () => {
    const response = await axios.get(apiLink('getPaymentSubTypes'))
    return response.data
})

// ** Balances total by params
export const getPaymentsTotal = createAsyncThunk('appRents/getPaymentsTotal', async data => {   
    const response = await axios.post(`http://164.90.210.46/finances/total?env=${process.env.REACT_APP_NODE_ENV}`, data)
    return response.data
})

// ** Misc
export const getPermissions = createAsyncThunk('appAccounts/getPermissions', async () => {
    const response = await axios.get(apiLink('getPermissions'))
    return response.data
})

export const getUserAccounts = createAsyncThunk('appAccounts/getUserAccounts', async () => {
    const response = await axios.get(apiLink('getAccounts'))
    return response.data
})

export const appAccountsSlice = createSlice({
    name: 'appAccounts',
    initialState: {
        accounts: [],
        total: 1,
        params: {},
        payments: [],        
        permissions: [],
        currentUser: []
    },
    reducers: {
        
    },
    extraReducers: builder => {
        builder
        .addCase(approvePayment.fulfilled, (state, action) => {     
            if (action.payload.response.status === 200) {
                const updatedPayments = state.payments.map(payment => { 
                    if (payment.payment_id === action.payload.data.payment_id) {                    
                        return {...payment, approve: {...payment.approve, ...action.payload.data}}
                    } else {                    
                        return {...payment}
                    }                
                })
                state.payments = [...updatedPayments]
            }
        }) 
        .addCase(getSystemPayments.fulfilled, (state, action) => {
            state.payments = action.payload.payments
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })    
        .addCase(getAccounts.fulfilled, (state, action) => {            
            state.accounts = action.payload.accounts
            state.params = action.payload.params
            state.total = action.payload.totalPages         
        })
        .addCase(getPermissions.fulfilled, (state, action) => {
            state.permissions = action.payload            
        })
        .addCase(getUserAccounts.fulfilled, (state, action) => {
            state.currentUser = action.payload.paymentAccounts.map((account) => ({ id: account.id, name: account.name, currency: account.currency }))
        })        
        .addCase(addAccount.fulfilled, (state, action) => {
            state.accounts = [...state.accounts, action.payload.data]            
            state.currentUser = [...state.currentUser, { id: action.payload.data.id, name: action.payload.data.name, currency: action.payload.data.currency }]
        })
        .addCase(removeAccount.fulfilled, (state, action) => {            
            state.currentUser = [...state.currentUser.filter(account => account.id !== action.payload.id)]
        })        
    }
})

export default appAccountsSlice.reducer
