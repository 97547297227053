// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Rent
export const getWarehousesList = createAsyncThunk('appWarehouses/getWarehousesList', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1
    const response = await axios.get(apiLink('getWarehouses', params))
    return {
        params,
        data: response.data,
        totalPages: response.params
    }
})

export const addWarehouse = createAsyncThunk('appWarehouses/addWarehouse', async (data) => {
    const response = await axios.post(apiLink('addWarehouse'), data)
    return response.data
})
export const updateWarehouse = createAsyncThunk('appWarehouses/updateWarehouse', async (data) => {
    const response = await axios.put(apiLink({name: 'updateWarehouse', value: data.id}), data)
    return response.data
})

export const getWarehouse = createAsyncThunk('appWarehouses/getWarehouse', async (id) => {
    const response = await axios.get(apiLink({name: 'getWarehouse', value: id}))
    return response.data
})

export const removeWarehouse = createAsyncThunk('appWarehouses/removeWarehouse', async (id) => {
    const response = await axios.delete(apiLink({name: 'removeWarehouse', value: id}))
    return response.data
})


export const getWarehouseProductsList = createAsyncThunk('appWarehouses/getWarehouseProductsList', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1
    const response = await axios.get(apiLink('getWarehouseProductsList', params))
    return {
        params,
        data: response.data,
        totalPages: response.params
    }
})

export const appWarehousesSlice = createSlice({
    name: 'appWarehouses',
    initialState: {
        data: [],
        total: 1,
        params: {},
        settings: [],
        selectedWarehouse: null    
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(getWarehousesList.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })
        .addCase(getWarehouseProductsList.fulfilled, (state, action) => {
            state.data = action.payload.data.items
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })
    }
})

export default appWarehousesSlice.reducer
