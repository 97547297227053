// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getCalendarData = createAsyncThunk('appCalendar/getCalendarData', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/calendar?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const addEvent = createAsyncThunk('appCalendar/addEvent', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/calendar/event/add?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/calendar/event/update?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/calendar/event/remove?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const getEvents = createAsyncThunk('appCalendar/getEvents', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/calendar/get/events?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const appCalendarSlice = createSlice({
   name: 'appCalendar',
   initialState: {
      date_from: null,
      date_to: null,
      data: [],
      dates: []
   },
   reducers: {},
   extraReducers: builder => {
      builder      
      .addCase(getCalendarData.fulfilled, (state, action) => {
         state.data = action.payload.list
         state.date_from = action.payload.date_from
         state.date_to = action.payload.date_to
         state.dates = action.payload.dates
      })      
   }
})

export default appCalendarSlice.reducer
