// ** I18n Imports
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

// ** Detect saved language after refresh
let lsLanguage = localStorage.getItem('i18nextLng')

// ** Set default language if not set
if (lsLanguage === null) {
   lsLanguage = 'ua'
   localStorage.setItem('i18nextLng', lsLanguage)
}

// I18n init
i18n
.use(Backend)
.use(initReactI18next)
.init({
   lng: lsLanguage,
   ns: [
      'accidents', 'blacklist', 'cars', 'common', 'company', 'credits', 'customers', 'reports', 'dashboard', 'calendar',
      'deposits', 'files', 'finances', 'insurances', 'navigation', 'pages', 'permissions', 'rents', 'actions'
   ],
   defaultNS: 'common',
   backend: {
      loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}/{{ns}}.json`
   },
   fallbackLng: 'en',
   debug: false
})

export default i18n
