// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getCompany = createAsyncThunk('appCompany/getCompany', async () => {
   const response = await axios.get(apiLink('getCompany'))
   return response.data
})

export const updateCompany = createAsyncThunk('appCompany/updateCompany', async data => {
   const response = await axios.put(apiLink('updateCompany'), data)
   return response.data
})

export const getCurrentSubscription = createAsyncThunk('appCompany/getCurrentSubscription', async () => {
   const response = await axios.get(apiLink('getCurrentSubscription'))
   return response.data
})

export const getSubscriptionPlans = createAsyncThunk('appCompany/getSubscriptionPlans', async () => {
   const response = await axios.get(apiLink('getSubscriptionPlans'))
   return response.data
})

export const requestSubscriptionChange = createAsyncThunk('appCompany/requestSubscriptionChange', async data => {   
   const response = await axios.post(`http://164.90.210.46/subscriptions/request?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const getPermissions = createAsyncThunk('appCompany/getPermissions', async () => {
   const response = await axios.get(apiLink('getPermissions'))
   return response.data
})

export const addPermission = createAsyncThunk('appCompany/addPermission', async data => {
   delete data.id
   const response = await axios.post(apiLink('addPermission'), data)
   return response.data
})

export const updatePermission = createAsyncThunk('appCompany/updatePermission', async data => {
   const response = await axios.put(apiLink({name: 'updatePermission', value: data.id}), data)
   return response.data
})

export const removePermission = createAsyncThunk('appCompany/removePermission', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePermission', value: id}))
   return response.data
})

export const getUsers = createAsyncThunk('appCompany/getUsers', async (params) => {
   const response = await axios.get(apiLink('getUsers', params))
   return {
      params,
      data: response.data.users,
      totalPages: response.data.total_elements
   }
})

export const addUser = createAsyncThunk('appCompany/addUser', async (data) => {
   const response = await axios.post(apiLink('addUser'), data)
   return response.data
})

export const updateUser = createAsyncThunk('appCompany/updateUser', async data => {
   const user_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateUser', value: user_id}), data)
   return response.data
})

export const deactivateUser = createAsyncThunk('appCompany/deactivateUser', async (id) => {
   const response = await axios.delete(apiLink({name: 'deactivateUser', value: id}))
   return response.data
})

// ** Profile actions
export const getProfile = createAsyncThunk('appCompany/getProfile', async () => {
   const response = await axios.get(apiLink('getProfile'))
   return response.data
})
export const updateProfile = createAsyncThunk('appCompany/updateProfile', async data => {
  const response = await axios.put(apiLink('updateProfile'), data)
  return response.data
})

export const getPricing = createAsyncThunk('appCompany/getPricing', async () => {
   const response = await axios.get('/api/company/pricing')
   return response.data
})

// ** Actions
export const getUserActions = createAsyncThunk('appCompany/getUserActions', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   if (!params.user_id) {
      delete params.user_id
   }
   const response = await axios.get(apiLink('getUserActions', params))   
   return {
      actions: response.data.actions,
      actions_params: params,
      actions_total: response.data.total_elements
   }
})
export const getUserActionsEventLink = createAsyncThunk('appCompany/getUserActionsEventLink', async (data) => {
   const response = await axios.post(`http://164.90.210.46/actions/link?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const getRentLinkByPeriodId = createAsyncThunk('appCompany/getRentLinkByPeriodId', async (data) => {
   const response = await axios.post(`http://164.90.210.46/actions/rent-link-period?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const getCreditLinkByPeriodId = createAsyncThunk('appCompany/getCreditLinkByPeriodId', async (data) => {
   const response = await axios.post(`http://164.90.210.46/actions/credit-link-period?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const appUsersSlice = createSlice({
   name: 'appCompany',
   initialState: {
      profile: [],
      company: [],
      users: [],
      permissions: [],
      actions: [],
      actions_params: {},
      actions_total: 0
   },
   reducers: {},
   extraReducers: builder => {
      builder
      .addCase(getProfile.fulfilled, (state, action) => {
         const role_name = action.payload.role.name
         state.profile = action.payload
         state.profile.role = role_name
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
         const role_name = action.payload.role.name,
               ability   = action.payload.role.permissions.map((item) => ({subject: item, action: 'read'}))

         state.profile = action.payload
         state.profile.role = role_name
         state.profile.ability = ability

         localStorage.setItem('userData', JSON.stringify(state.profile))
      })
      .addCase(getCompany.fulfilled, (state, action) => {
         state.company = action.payload
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
         state.company = action.payload
      })
      .addCase(getUsers.fulfilled, (state, action) => {
         state.users = action.payload.data
      })
      .addCase(updateUser.fulfilled, (state, action)  => {
         const index = state.users.findIndex(obj => obj.id === action.payload.id)
         state.users[index] = action.payload
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
         state.permissions = action.payload
      })
      .addCase(addPermission.fulfilled, (state, action) => {
         state.permissions = [...state.permissions, action.payload]
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
         const index = state.permissions.findIndex(obj => obj.id === action.payload.id)
         state.permissions[index] = action.payload
      })
      .addCase(removePermission.fulfilled, (state, action) => {
         const index = state.permissions.findIndex(obj => obj.id === action.meta.arg)
         state.permissions = [
            ...state.permissions.slice(0, index),
            ...state.permissions.slice(index + 1)
         ]
      })
      .addCase(getUserActions.fulfilled, (state, action) => {
         state.actions = action.payload.actions
         state.actions_params = action.payload.actions_params
         state.actions_total = action.payload.actions_total
      })
   }
})

export default appUsersSlice.reducer
