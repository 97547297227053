// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getCustomersList = createAsyncThunk('appCustomers/getCustomersList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getCustomersList', params))
   return {
      params,
      data: response.data.customers,
      totalPages: response.data.total_elements
   }
})

export const getCustomersArchiveList = createAsyncThunk('appCustomers/getCustomersArchiveList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getCustomersList', params))
   return {
      params,
      data: response.data.customers,
      totalPages: response.data.total_elements
   }
})

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async id => {
   const response = await axios.get(apiLink({name: 'getCustomer', value: id}))
   return response.data
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (data) => {
   const response = await axios.post(apiLink('addCustomer'), data)
   return response.data
})

export const updateCustomer = createAsyncThunk('appCustomers/updateCustomer', async (data) => {
   const customer_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateCustomer', value: customer_id}), data)
   return response.data
})

export const removeCustomer = createAsyncThunk('appCustomers/removeCustomer', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeCustomer', value: id}))
   return response
})

export const reactivateCustomer = createAsyncThunk('appCustomers/reactivateCustomer', async (customerId) => {
   const response = await axios.put(apiLink({name: 'reactivateCustomer', value: customerId}))
   return response.data
})

export const resetPassword = createAsyncThunk('appCustomers/resetPassword', async (data) => {
   const customer_id = data.id
   delete data.id

   const response = await axios.post(apiLink({name: 'resetPassword', value: customer_id}), data)
   return response.status
})

export const addToBlacklist = createAsyncThunk('appCustomers/addToBlacklist', async (data) => {
   const customer_id = data.id
   delete data.id

   const response = await axios.post(apiLink({name: 'addToBlacklist', value: customer_id}, data))
   return response.data
})

export const removeFromBlacklist = createAsyncThunk('appCustomers/removeFromBlacklist', async (data) => {
   const customer_id = data.id
   delete data.id

   const response = await axios.delete(apiLink({name: 'removeFromBlacklist', value: customer_id}, data))
   return response.data
})

// Contacts
export const addContact = createAsyncThunk('appCustomers/addContact', async ({customerId, contact}) => {
   const response = await axios.post(apiLink({name: 'addContact', value: customerId}), contact)
   return response.data
})
export const updateContact = createAsyncThunk('appCustomers/updateContact', async ({customerId, contact}) => {
   const response = await axios.put(apiLink({name: 'updateContact', value: customerId}), contact)
   return response.data
})
export const removeContact = createAsyncThunk('appCustomers/removeContact', async ({customerId, contact_id}) => {
   const response = await axios.delete(apiLink({name: 'removeContact', values: [customerId, contact_id]}))
   return {id, status: response.status}
})

// Relatives
export const addRelative = createAsyncThunk('appCustomers/addRelative', async (data) => {
   const customer_id = data.customer_id
   delete data.customer_id

   const response = await axios.post(apiLink({name: 'addRelative', value: customer_id}), data)
   return response.data.relatives
})

export const updateRelative = createAsyncThunk('appCustomers/updateRelative', async (data) => {
   const customer_id = data.customer_id
   delete data.customer_id

   const response = await axios.put(apiLink({name: 'updateRelative', value: customer_id}), data)
   return response.data.relatives
})

export const removeRelative = createAsyncThunk('appCustomers/removeRelative', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeRelative', value: id}))
   return {id, status: response.status}
})

// Get Surcharge
export const getSurcharge = createAsyncThunk('appCustomers/getSurcharge', async id => {
   const response = await axios.get('/api/users/surcharge', { id })
   return response.data.surcharge
})

// Get Deposits
export const getDeposits = createAsyncThunk('appCustomers/getDeposits', async (customer_id) => {
   const response = await axios.get(apiLink('getDepositsList', {customer_id}))
   return response.data.deposits
})

// Media files
export const customerUploadFile = createAsyncThunk('appCustomers/customerUploadFile', async ({customer_id, type, typeOwner, data}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'customerUploadFile', value: customer_id}, {type, typeOwner}),
        data,
        headers: { "Content-Type": "multipart/form-data" }
      })
   return response.data
})
export const getMedia = createAsyncThunk('appCustomers/getMedia', async ({customer_id, types}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: 'CUSTOMER'}), {
      ids: [customer_id],
      types
   })
   return {files: response.data.files, customer_id}
})
export const addMedia = createAsyncThunk('appCustomers/addMedia', async ({customer_id, type, typeOwner, file}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'customerUploadFile', value: customer_id}, {type, typeOwner}),
        data: { file },
        headers: { "Content-Type": "multipart/form-data" }
      })
   return response.data
})
export const removeMedia = createAsyncThunk('appCustomers/removeMedia', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'CUSTOMER'}))
   return {id, status: response.status}
})

// ** Accidents
export const getAccidents = createAsyncThunk('appCustomers/getAccidents', async (customer_id) => {
   const response = await axios.get(apiLink('getAccidentsList', {customer_id}))
   return response.data.roadAccidents
})

// ** Blacklist
export const checkBlacklistCustomer = createAsyncThunk('appCustomers/checkBlacklistCustomer', async (params) => {
   const response = await axios.get(apiLink('checkBlacklistCustomer', params))   
   return response.data
})

// ** Car customer history
export const carCustomerHistory = createAsyncThunk('appCustomers/carCustomerHistory', async (params) => {
   const response = await axios.get(apiLink('carCustomerHistory', params))
   return response.data.histories
})

// ** export customers list
export const getCustomersListForExport = createAsyncThunk('appCustomers/getCustomersListForExport', async data => {
   const response = await axios.post(`http://164.90.210.46/customers/list?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})
export const downloadCustomersListForExport = createAsyncThunk('appCustomers/downloadCustomersListForExport', async data => {
   const response = await axios.post(`http://164.90.210.46/customers/list/download?env=${process.env.REACT_APP_NODE_ENV}`, data, {
      responseType: 'blob'
   })
   return response.data
})


export const appCustomersSlice = createSlice({
   name: 'appCustomers',
   initialState: {
      data: [],
      archive: [],
      total: 1,
      params: {},
      media: [],
      surcharge: [],
      deposits: [],
      accidents: [],
      selectedCustomer: null,
      customersListData: []
   },
   reducers: {
      addCustomersListData: (state, action) => {
         state.customersListData = action.payload
      }
   },
   extraReducers: builder => {
      builder
      //.addCase(getAllData.fulfilled, (state, action) => {
         //state.allData = action.payload
      //})
      .addCase(getCustomersList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getCustomersArchiveList.fulfilled, (state, action) => {
         state.archive = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
         state.selectedCustomer = action.payload
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
         state.selectedCustomer = action.payload
      })
      .addCase(reactivateCustomer.fulfilled, (state, action) => {
         state.selectedCustomer = action.payload
      })
      .addCase(addRelative.fulfilled, (state, action) => {
         state.selectedCustomer.relatives = action.payload
      })
      .addCase(updateRelative.fulfilled, (state, action) => {
         state.selectedCustomer.relatives = action.payload
      })
      .addCase(removeRelative.fulfilled, (state, action) => {
         if (action.payload.status === 200) {
            state.selectedCustomer.relatives = state.selectedCustomer.relatives.filter((item) => item.id !== action.payload.id)
         }
      })
      .addCase(getDeposits.fulfilled, (state, action) => {
         state.deposits = action.payload
      })
      .addCase(getSurcharge.fulfilled, (state, action) => {
         state.surcharge = action.payload
      })
      .addCase(getMedia.fulfilled, (state, action) => {
         const uniqueMedia = [...state.media, ...action.payload.files].reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.file_id === current.file_id)) {
               accumulator.push(current)
            }
            return accumulator
         }, [])
         state.media = uniqueMedia.filter((item) => item.customer_id === action.payload.customer_id)
      })
      .addCase(removeMedia.fulfilled, (state, action) => {
         if (action.payload.status === 200) {
            state.media = state.media.filter(item => item.file_id !== action.payload.id)
         }
      })
      .addCase(getAccidents.fulfilled, (state, action) => {
         state.accidents = action.payload
      })
      .addCase(addToBlacklist.fulfilled, (state, action) => {
         state.selectedCustomer = action.payload
      })
      .addCase(removeFromBlacklist.fulfilled, (state, action) => {
         state.selectedCustomer = action.payload
      })
   }
})

export const { addCustomersListData } = appCustomersSlice.actions

export default appCustomersSlice.reducer
