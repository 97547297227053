// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Rent
export const getRentsList = createAsyncThunk('appRents/getRentsList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getRentsList', params))
   return {
      params,
      data: response.data.rents,
      totalPages: response.data.total_elements
   }
})
export const getRent = createAsyncThunk('appRents/getRent', async id => {
   const response = await axios.get(apiLink({name: 'getRent', value: id}))
   return response.data
})
export const addRent = createAsyncThunk('appRents/addRent', async (data) => {
   const response = await axios.post(apiLink('addRent'), data)
   return response.data
})
export const addDynamicDeposit = createAsyncThunk('appRents/addDynamicDeposit', async data => {
   const id = data.rent_id
   delete data.rent_id
   const response = await axios.post(apiLink({name: 'addDynamicDeposit', value: id}), data)
   return response.data
})
export const addRentPeriodDetails = createAsyncThunk('appRents/addRentPeriodDetails', async data => {
   const id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'addRentPeriodDetails', value: id}), data)
   return response.data
})
export const closeRentPeriod = createAsyncThunk('appRents/closeRentPeriod', async id => {
   const response = await axios.put(apiLink({name: 'closeRentPeriod', value: id}))
   return response.data
})
export const createRentPeriod = createAsyncThunk('appRents/createRentPeriod', async id => {
   const response = await axios.post(apiLink({name: 'createRentPeriod', value: id}))
   return response.data
})
export const shiftRentPeriod = createAsyncThunk('appRents/shiftRentPeriod', async params => {
   const id = params.id
   delete params.id
   const response = await axios.post(apiLink({name: 'shiftRentPeriod', value: id}, params))
   return response.data
})
export const setLastRentPeriod = createAsyncThunk('appRents/setLastRentPeriod', async params => {
   const id = params.id
   delete params.id
   const response = await axios.put(apiLink({name: 'setLastRentPeriod', value: id}, params))
   return response.data
})
export const updateActualPeriodPrice = createAsyncThunk('appRents/updateActualPeriodPrice', async params => {
   const id = params.id
   delete params.id
   const response = await axios.put(apiLink({name: 'updateActualPeriodPrice', value: id}, params))
   return response.data
})

export const setRentPrice = createAsyncThunk('appRents/setRentPrice', async (params) => {
   const id = params.id
   delete params.id
   const response = await axios.put(apiLink({name: 'setRentPrice', value: id}, params))      
   return response.data
})

export const stopRent = createAsyncThunk('appRents/stopRent', async (id) => {
   const response = await axios.delete(apiLink({name: 'stopRent', value: id}))
   return response.data
})

export const deleteCurrentPeriod = createAsyncThunk('appRents/deleteCurrentPeriod', async (id) => {
   const response = await axios.delete(apiLink({name: 'deleteCurrentPeriod', value: id}))
   return response.data
})

// ** Mark visit
export const rentPeriodVisited = createAsyncThunk('appRents/rentPeriodVisited', async data => {
   const id = data.period_id
   delete data.period_id
   const response = await axios.post(apiLink({name: 'rentPeriodVisited', value: id}, data))
   return response.data
})

// ** Payments
export const addPayment = createAsyncThunk('appRents/addPayment', async (data) => {
   return await axios.post(apiLink('addPayment'), data)
})
export const removeRentPayment = createAsyncThunk('appRents/removeRentPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'RENT'}))
   return response.data
})

// ** Deposit
export const removeDepositPayment = createAsyncThunk('appRents/removeDepositPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'DEPOSIT'}))
   return response.data
})
export const removeDynamicDeposit = createAsyncThunk('appRents/removeDynamicDeposit', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeDynamicDeposit', value: id}))
   return response.data
})

// ** Customers list
export const getRentCustomers = createAsyncThunk('appRents/getRentCustomers', async () => {
   const params = {active: true, sort_list: 'first_name', sort_order: 'ASC', rows: 1000, blacklist: false}
   const response = await axios.get(apiLink('getCustomersShortList', params))
   return response.data.customers
})
// ** Cars list
export const getRentCars = createAsyncThunk('appRents/getRentCars', async () => {
   const params = {sort_list: 'id', sort_order: 'ASC', rows: 1000, status: 'BASE', has_customer: false, in_road_accident_lock: false}
   const response = await axios.get(apiLink('getCarsShortList', params))
   return response.data.cars
})

// ** Rent Settings
export const getRentSettings = createAsyncThunk('appRents/getRentSettings', async () => {
   const response = await axios.get(apiLink('getRentSettings'))
   return response.data.rentSettings
})
export const addRentSettings = createAsyncThunk('appRents/addRentSettings', async (data) => {
   delete data.id
   const response = await axios.post(apiLink('addRentSettings'), data)
   return response.data
})
export const updateRentSettings = createAsyncThunk('appRents/updateRentSettings', async (data) => {
   const preset_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateRentsettings', value: preset_id}), data)
   return response.data
})
export const removeRentSettings = createAsyncThunk('appRents/removeRentSettings', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeRentSettings', value: id}))
   return response.data
})

// ** Car replacement
export const carReplacement = createAsyncThunk('appRents/carReplacement', async (data) => {
   const id = data.id
   delete data.id
   const response = await axios.post(apiLink({name: 'carReplacement', value: id}), data)
   return response.data
})

// ** Daily report
export const rentDailyReport = createAsyncThunk('appRents/rentDailyReport', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/report/rent-daily?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

// ** Get actual car on date of rent
export const getActualCarByRentOnDate = createAsyncThunk('appRents/getActualCarByRentOnDate', async data => {   
   const response = await axios.post(`http://api.mfauto.com.ua/rent/car-replacement?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const appRentsSlice = createSlice({
  name: 'appRents',
  initialState: {
    data: [],
    total: 1,
    params: {},
    settings: [],
    selectedRent: null,
    customers: [],
    cars: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRentsList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getRent.fulfilled, (state, action) => {
         state.selectedRent = action.payload
      })
      .addCase(getRentSettings.fulfilled, (state, action) => {
         state.settings = action.payload
      })
      .addCase(updateRentSettings.fulfilled, (state, action) => {
         state.settings = action.payload
      })
      .addCase(getRentCustomers.fulfilled, (state, action) => {
         state.customers = action.payload
      })
      .addCase(getRentCars.fulfilled, (state, action) => {
         state.cars = action.payload
      })
  }
})

export default appRentsSlice.reducer
