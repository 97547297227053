// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getStats = createAsyncThunk('appDashboard/getStats', async data => {   
   const response = await axios.post(`http://164.90.210.46/stats?env=${process.env.REACT_APP_NODE_ENV}`, data)
   return response.data
})

export const appDashboardSlice = createSlice({
   name: 'appDashboard',
   initialState: {
      stats: false
   },
   reducers: {},
   extraReducers: builder => {
      builder      
      .addCase(getStats.fulfilled, (state, action) => {
         state.stats = action.payload
      })      
   }
})

export default appDashboardSlice.reducer
