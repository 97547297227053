// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Rent
export const getProductsList = createAsyncThunk('appProducts/getProductsList', async params => {
    params.page = params.page === 0 ? 0 : params.page - 1
    const response = await axios.get(apiLink('getProductsList', params))
    return {
        params,
        data: response.data.items,
        totalPages: response.total_elements
    }
})
export const addProduct = createAsyncThunk('appProducts/addProduct', async (data) => {
    const response = await axios.post(apiLink('addProduct'), data)
    return response.data
})
export const updateProduct = createAsyncThunk('appProducts/updateWarehouse', async (data) => {
    const product_id = data.id
    delete data.id
    const response = await axios.put(apiLink({name: 'updateProduct', value: product_id}), data)
    return response.data
})
export const getProduct = createAsyncThunk('appProducts/getProduct', async (id) => {
    const response = await axios.get(apiLink({name: 'getProduct', value: id}))
    return response.data
})
export const removeProduct = createAsyncThunk('appProducts/removeProduct', async (id) => {
    const response = await axios.delete(apiLink({name: 'removeProduct', value: id}))
    return response.data
})
export const getProductStock = createAsyncThunk('appProducts/getProductAvailability', async (id) => {
    const response = await axios.get(apiLink({name: 'getProductAvailability', value: id}))
    return response.data
})

export const getProductGroups = createAsyncThunk('appProducts/getProductGroups', async params => {
    const response = await axios.get(apiLink({name: 'getProductGroups'}, params))
    return response.data.groups
})
export const getProductGroupChildren = createAsyncThunk('appProducts/getProductGroupChildren', async data => {
    const response = await axios.get(apiLink({name: 'getProductGroupChildren', value: data.group_id}))
    return response.data
})
export const getProductGroup = createAsyncThunk('appProducts/getProductGroup', async (id) => {
    const response = await axios.get(apiLink({name: 'getProductGroup', value: id}))
    return response.data
})
export const addProductGroup = createAsyncThunk('appProducts/addProductGroup', async (data) => {
    if (data.parent_group_id === 0) {
        delete data.parent_group_id
    }
    const response = await axios.post(apiLink('addProductGroup'), data)
    return response.data
})
export const editProductGroup = createAsyncThunk('appProducts/editProductGroup', async (data) => {
    const response = await axios.put(apiLink({name: 'editProductGroup', value: data.id}), data)
    return response.data
})
export const removeProductGroup = createAsyncThunk('appProducts/removeProductGroup', async (id) => {
    const response = await axios.delete(apiLink({name: 'removeProductGroup', value: id}))
    return response.data
})
export const getProductsByGroup = createAsyncThunk('appProducts/getProductsByGroup', async id => {
    const response = await axios.get(apiLink('getProductsList', { recursive_parent_group_id: id }))
    return response.data
})

// ** Stock
export const addProductStockQuantity = createAsyncThunk('appProducts/addProductStockQuantity', async (data) => {
    const response = await axios.post(apiLink('addProductStockQuantity'), data)
    return response.data
})
export const updateProductStockQuantity = createAsyncThunk('appProducts/updateProductStockQuantity', async (data) => {
    const response = await axios.put(apiLink({name: 'updateProductStockQuantity', value: data.stock_item_id}), data)
    return response.data
})

export const appProductsSlice = createSlice({
    name: 'appProducts',
    initialState: {
        data: [],
        total: 1,
        params: {},
        settings: [],
        selectedProduct: null,
        parentGroupName: '',
        parentGroupId: '',
        groups: []
    },
    reducers: {},
    extraReducers: builder => {
    builder
        .addCase(getProductsList.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
        })      
        .addCase(getProductGroups.fulfilled, (state, action) => {

            const map = new Map()
            const tree = []

            action.payload.forEach(item => {
                map.set(item.id, { ...item, children: [] })
            })
                        
            action.payload.forEach(item => {
                if (item.parent_group_id) {                    
                    map.get(item.parent_group_id).children.push(map.get(item.id))
                } else {                    
                    tree.push(map.get(item.id))
                }
            })            

            state.groups = tree
        })
        .addCase(getProductGroupChildren.fulfilled, (state, action) => {
            state.groups = action.payload.children
            state.parentGroupName = action.payload.name
            state.parentGroupId = action.payload.id
        })
        .addCase(getProduct.fulfilled, (state, action) => {
            state.selectedProduct = action.payload            
        })
    }
})

export default appProductsSlice.reducer
