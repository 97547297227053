// ** Import Functions
import { createParamsListForTable } from '@src/utility/helpFunctions'

// Server config
let serverConfig = false

if (process.env.REACT_APP_NODE_ENV === 'development') {
   serverConfig = {
      path: 'http://167.99.252.5:8080',
      api:  'http://167.99.252.5:8080/api/admin/v1'
   }
}
if (process.env.REACT_APP_NODE_ENV === 'production') {
   serverConfig = {
      path: 'http://209.38.252.69:8080',
      api:  'http://209.38.252.69:8080/api/admin/v1'
   }
}

// Endpoints list
const endPoints = {
   getUserProfileDetails: '/profile',

   getCustomersList: '/customers',
   getCustomersShortList: '/customers/short-list',
   addCustomer: '/customers',
   getCustomer: '/customers/{value}',
   updateCustomer: '/customers/{value}',
   removeCustomer: '/customers/{value}',
   addContact: '/customers/{value}/contact',
   updateContact: '/customers/{value}/contact',
   removeContact: '/customers/{value1}/contact/{value2}',
   addRelative: '/customers/{value}/relative',
   updateRelative: '/customers/{value}/relative',
   removeRelative: '/customers/relative/{value}',
   customerUploadFile: '/files/{value}',
   reactivateCustomer: '/customers/{value}/reactivate',
   resetPassword: '/customers/{value}',
   checkBlacklistCustomer: '/customers/blacklist',
   addToBlacklist: '/customers/{value}/black-list',
   removeFromBlacklist: '/customers/{value}/black-list',

   getCompany: '/company',
   updateCompany: '/company',
   getPermissions: '/roles/permissions',
   addPermission: '/roles',
   updatePermission: '/roles/{value}',
   removePermission: '/roles/{value}',
   getUsers: '/users',
   addUser: '/users',
   updateUser: '/users/{value}',
   deactivateUser: '/users/{value}',
   getProfile: '/profile',
   updateProfile: '/profile',
   profileUploadAvatar: '/files/{value}',
   getCurrentSubscription: '/subscriptions',
   getSubscriptionPlans: '/subscriptions/plans',
   getUserActions: '/user-actions',

   getCarsList: '/cars',
   addCar: '/cars',
   getCarsShortList: '/cars/short-list',
   getCarBrands: '/cars/brands',
   getCar: '/cars/{value}',
   updateCar: '/cars/{value}',
   removeCar: '/cars/{value}',
   carUploadFile: '/files/{value}',
   carCustomerHistory: '/cars/customer-history',
   carByIdCustomerHistory: '/cars/{value}/customer-history',
   changeCarStatus: '/cars/{value}/status',
   addCarStatusComment: '/cars/customer-history/{value}/comment',
   removeCarStatusComment: '/cars/customer-history/comment/{value}',

   getCreditsList: '/credits',
   addCredit: '/credits',
   editCredit: '/credits/{value}',
   getCredit: '/credits/{value}',
   getCreditPeriods: '/credits/{value}/periods',
   getCreditSettings: '/credit-settings',
   addCreditSettings: '/credit-settings',
   updateCreditSettings: '/credit-settings/{value}',
   removeCreditSettings: '/credit-settings/{value}',
   getFirstPayemntPayments: '/credits/{value}/first-payments',
   changeCreditStatus: '/credits/{value}',
   recalculateCredit: '/credits/{value}/recalculate',
   calculateCredit: '/credits/{value}/calculate',
   calculateTotalAmount: '/credits/calculate-total-amount',
   creditPeriodVisited: '/credits/visit/{value}',

   getRentsList: '/rents',
   addRent: '/rents',
   getRent: '/rents/{value}',
   stopRent: '/rents/{value}',
   addDynamicDeposit: '/rents/{value}/custom-deposit',
   removeDynamicDeposit: '/rents/custom-deposit/{value}',
   addRentPeriodDetails: '/rents/{value}/period-info',
   closeRentPeriod: '/rents/{value}/close-period',
   createRentPeriod: '/rents/{value}/create-next-period',
   shiftRentPeriod: '/rents/{value}/shift-next-period',
   setLastRentPeriod: '/rents/{value}/update-end-date',
   getRentSettings: '/rent-settings',
   addRentSettings: '/rent-settings',
   updateRentsettings: '/rent-settings/{value}',
   removeRentSettings: '/rent-settings/{value}',
   carReplacement: '/rents/{value}/car-replacement',
   setRentPrice: '/rents/{value}/update-expected-payment',
   updateActualPeriodPrice: '/rents/{value}/update-actual-period-price',
   deleteCurrentPeriod: '/rents/{value}/delete-current-period',
   rentPeriodVisited: '/rents/visit/{value}',

   addPayment: '/payments/create',
   removePayment: '/payments/refund/{value}',

   getExchangeRate: '/exchange-rate',
   updateExchangeRate: '/exchange-rate/{value}',

   getDepositsList: '/deposits',

   getInsurancesList: '/car-insurance',
   getInsurance: '/car-insurance/{value}',
   addInsurance: '/car-insurance',
   removeInsurance: '/car-insurance/{value}',

   getFiles: '/files',
   getFilesByParams: '/files/{value}',
   uploadFile: '/files/{value}',
   removeFile: '/files/{value}',

   getAccidentsList: '/road-accidents',
   addAccident: '/road-accidents',
   getAccident: '/road-accidents/{value}',
   updateAccident: '/road-accidents/{value}',
   completeAccident: '/road-accidents/{value}/complete',
   addAccidentInsuranceAppeal: '/road-accidents/{value}/insurance-appeal',
   updateAccidentInsuranceAppeal: '/road-accidents/insurance-appeal/{value}',
   addAccidentInsuranceAppealExpectedPayment: '/road-accidents/insurance-appeal/{value}/expected-payment',
   removeAccidentInsuranceAppealEexpectedPayment: '/road-accidents/insurance-appeal/expected-payment/{value}',
   updateAccidentInsuranceAppealExpertExamined: '/road-accidents/insurance-appeal/{value}/expert-examined',
   removeAccidentAppeal: '/road-accidents/insurance-appeal/{value}',
   restoreAccident: '/road-accidents/{value}/restore',

   getPaymentsList: '/finance',
   getPaymentSubTypes: '/company/payment-subtypes', // get
   addPaymentSubTypes: '/company/payment-subtypes', // post
   updatePaymentSubTypes: '/company/payment-subtypes/{value}', // put
   removePaymentSubTypes: '/company/payment-subtypes/{value}', // delete
   activatePaymentSubTypes: '/company/payment-subtypes/{value}/activate', // put
   getAccounts: '/payment-accounts',
   addAccount: '/payment-accounts',
   updateAccount: '/payment-accounts/{value}',
   removeAccount: '/payment-accounts/{value}',
   getAccessRoles: '/payment-accounts/{value}/access',
   updateAccountAccess: '/payment-accounts/{value}/access',
   getAccountBalance: '/payment-accounts/{value}/balance',
   approvePayment: '/payments/approve/{value}',
   getRelatedPayments: '/finance/relate',
   addPaymentRelation: '/payments/relate',
   deleteRelatedPayment: '/payments/relate',

   getWarehouses: '/api/service-station/v1/stocks',
   addWarehouse: '/api/service-station/v1/stocks',
   getWarehouse: '/api/service-station/v1/stocks/{value}',
   updateWarehouse: '/api/service-station/v1/stocks/{value}',
   deleteWarehouse: '/api/service-station/v1/stocks/{value}',
   getWarehouseProductsList: '/api/service-station/v1/stock-items',

   getSuppliers: '/api/service-station/v1/stocks/suppliers',
   addSupplier: '/api/service-station/v1/stocks/suppliers',
   getSupplier: '/api/service-station/v1/stocks/suppliers/{value}',
   updateSupplier: '/api/service-station/v1/stocks/suppliers/{value}',
   deleteSupplier: '/api/service-station/v1/stocks/suppliers/{value}',
   
   getSuppliesList: '/api/service-station/v1/stocks/supplies',
   addSupply: '/api/service-station/v1/stocks/supplies',
   getSupply: '/api/service-station/v1/stocks/supplies/{value}',

   getProductGroups: '/api/service-station/v1/item-groups', //'/api/service-station/v1/item-groups/{value}/children',
   getProductGroupChildren: '/api/service-station/v1/item-groups/{value}/children',
   addProductGroup: '/api/service-station/v1/item-groups',
   getProductGroup: '/api/service-station/v1/item-groups/{value}',
   editProductGroup: '/api/service-station/v1/item-groups/{value}',
   deleteProductGroup: '/api/service-station/v1/item-groups/{value}',

   getProductsList: '/api/service-station/v1/items',
   addProduct: '/api/service-station/v1/items',
   getProduct: '/api/service-station/v1/items/{value}',
   updateProduct: '/api/service-station/v1/items/{value}',
   deleteProduct: '/api/service-station/v1/items/{value}',
   getProductAvailability: '/api/service-station/v1/items/{value}/availability',
   getProductStock: '/api/service-station/v1/stock-items/{value}',
   addProductStockQuantity: '/api/service-station/v1/stock-items',
   updateProductStockQuantity: '/api/service-station/v1/stock-items/{value}'
}

export const ttl_delay = 10// mins

export const checkTtlExpired = (ttl) => {
   const result = Math.ceil((new Date().getTime() - ttl.getTime()) / (1000 * 60 * 1))
   return result > ttl_delay
}

export const apiLink = (endPoint, params = null, clear_params = true) => {

   const uri = params !== null ? createParamsListForTable(params, clear_params) : ''
   if (typeof endPoint === 'object') {

      const startLink = endPoints[endPoint.name].includes('/api/') ? serverConfig.path : serverConfig.api

      if (endPoint.values !== undefined) {

         let path = endPoints[endPoint.name]

         endPoint.values.map((item, index) => {
            path = path.replace(`{value${index + 1}}`, item)
         })

         return startLink + path + uri
      }      

      return startLink + endPoints[endPoint.name].replace('{value}', endPoint.value) + uri

   } else {

      const startLink = endPoints[endPoint].includes('/api/') ? serverConfig.path : serverConfig.api

      return startLink + endPoints[endPoint] + uri
      
   }

}

export { serverConfig }

export default apiLink